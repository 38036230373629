import React, { useEffect } from "react";
import Loading from "common/components/loading/Loading";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setUserAuth, logout, setUserProfile } from "common/redux/actions/userAction";
import { setAuthToken } from "common/redux/actions/authToken";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Cookies from "js-cookie";

const Callback = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("verifyToken");
  const dispatch = useDispatch();

  const baseUrl = process.env.REACT_APP_AUTH_GS_AUTHENTICATION_API_URL;
  const baseUrlEntity = process.env.REACT_APP_ENTITY_MNG_URL;

  useEffect(() => {
    verifyToken(token);
  });

  // Verify Token with AUTH GS
  async function verifyToken(token) {
    try {
      const response = await axios.post(`${baseUrl}/api/v1/token/verify`, { token: token });
      // Save user auth details in redux
      dispatch(setUserAuth(response.data.profile));
      dispatch(setAuthToken(response.data.tokens));
      Cookies.set("elevadeToken", response.data.tokens.token, {
        expires: 1,
      });
      // Call entity api for user profile
      GetUserByEmail(response.data.profile.email);
    } catch (errors) {
      console.log(errors);
    }
  }

  // Get user profile from ENTITY
  async function GetUserByEmail(email) {
    const queryDetails = `query allUsers{
      allUsers(email:"${email}"){
        edges{
          node{
            id
            fullName
            email
            userprofile {
              staffId
              dateJoined
              photo
              entity {
                id
                registeredName
                logo
                code
              }
            }
            roles{
              edges{
                node{
                  id
                  name
                  rolepermissionSet{
                    edges{
                      node{
                        permission{
                          id
                          code
                        }
                        role {
                          id
                          name
                          roleType{
                            id
                            code
                            name
                          }
                        }
                        canCreate
                        canRetrieve
                        canUpdate
                        canDelete
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`;
    const reqQuery = {
      query: queryDetails,
      variables: { email },
    };
    try {
      const response = await axios.post(`${baseUrlEntity}`, reqQuery);
      // Logout user if user doesnt exist in Entity
      if (response.status === 404) dispatch(logout());
      // else redirect to /main
      else {
        setUserProfileAndPermissions(response.data);
        navigate("/main", { replace: true });
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  function setUserProfileAndPermissions(user) {
    const userprofile = user.data.allUsers.edges.map((user) => {
      return {
        id: user.node.id,
        email: user.node.email,
        fullName: user.node.fullName,
        staffId: user.node.userprofile.staffId,
        company: user.node.userprofile.entity,
        permission: setUserPermission(user.node.roles),
      };
    });
    // Store user details in redux
    dispatch(setUserProfile(userprofile[0]));
  }

  function setUserPermission(roles) {
    const userPermissions = [];
    if (!_.isEmpty(roles)) {
      roles.edges.map((role) => {
        if (!_.isEmpty(role)) {
          role?.node?.rolepermissionSet?.edges?.map((rp) => {
            if (!_.isEmpty(rp)) {
              userPermissions.push({
                id: rp?.node?.permission?.id,
                name: role?.node?.name,
                code: rp?.node?.permission?.code,
                canCreate: rp?.node?.canCreate,
                canRetrieve: rp?.node?.canRetrieve,
                canUpdate: rp?.node?.canUpdate,
                canDelete: rp?.node?.canDelete,
              });
            }
          });
        }
      });
    }
    return userPermissions;
  }

  return (
    <center>
      <Loading style={{ width: "200px", marginTop: "200px" }} />
    </center>
  );
};

export default Callback;
