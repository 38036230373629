export const PERMISSION_CODE = {
  //ACM VIEW
  ACM_AIRCRAFT_MONITORING: "fleet.acm.view.aircraftmonitoring",
  ACM_FLIGHT_REPORT: "fleet.acm.view.flightreport",
  ACM_INBOX: "fleet.acm.view.inbox",
  ACM_RDD: "fleet.acm.view.repetitivedefect",
  //ACM MANAGE(ACTION)
  ACM_MANAGE_FLIGHT_REPORT: "fleet.acm.manage.flightreport", //currently not applied in flight report (label, any actions)

  //Analytics,
  ANALYTICS_DASHBOARD: "fleet.ana.view.analytics",
  ANALYTICS_ECAM_DISPATCH_ALERT: "fleet.ana.view.ecamdispatchalert",

  //DASR VIEW
  DASR_DASHBOARD: "fleet.dasr.view.dashboard",
  DASR_AIRCRAFT_LIST: "fleet.dasr.view.aircraftlist",
  DASR_MAINTENANCE_STATUS: "fleet.dasr.view.maintenancestatus",
  DASR_DDML_REPORT: "fleet.dasr.view.ddmlreports",
  DASR_PART_REQUEST: "fleet.dasr.part.request",
  DASR_CLOSED_REPORTS: "fleet.dasr.view.closedreports",
  DASR_ARCHIEVE_RECORDS: "fleet.dasr.view.archiverecords",
  DASR_ASSIGNMENT_DRAFT: "fleet.dasr.view.assignmentdrafts",
  DASR_TASK_ASSIGNMENT: "fleet.dasr.view.taskassignment",
  DASR_ASSIGNMENT_REPORT: "fleet.dasr.view.assignmentreport",
  //DASR_ MANAGE(ACTION)
  DASR_TASK_ASSIGNMENT_ASSIGNEE: "fleet.dasr.manage.assignee",
  DASR_MANAGE_DDML: "fleet.dasr.manage.ddml",
  DASR_MANAGE_MOC: "fleet.dasr.manage.moc",
  DASR_MANAGE_RECTIFICATION: "fleet.dasr.manage.rectification", //formerly know as enginireeing tab
  DASR_MANAGE_MATERIAL: "fleet.dasr.manage.material",
  DASR_MANAGE_PLANNING: "fleet.dasr.manage.planning",
  DASR_MANAGE_SHIFT_REPORT: "fleet.dasr.manage.shiftreport",
  DASR_SCHEDULED_TASK: "fleet.dasr.manage.scheduledtask",
  DASR_MANAGE_APPROVE_CLOSED_REPORT: "fleet.dasr.approve.closedreports",
  DASR_MANAGE_REJECT_CLOSED_REPORT: "fleet.dasr.reject.closedreports",
  DASR_MANAGE_PUSH_NEXT_SHIFT: "fleet.dasr.push.next.shift",
  DASR_MANAGE_PART_REQUEST_EDIT_STOCK_AVAILABILITY: "fleet.dasr.part.request.edit.stock.availability",

  //CBM VIEW/ACTION,
  CBM_LOPA: "fleet.cbm.manage.lopa",
  CBM_EQUIPMENT: "fleet.cbm.manage.equipment",
  CBM_INSPECTION_REPORT: "fleet.cbm.manage.inspectionreport",

  //Setting VIEW/ACTION
  SETTING_WARNING_MANAGEMENT: "fleet.set.manage.warningmanagement",
  SETTING_MANUAL_COLLECTION: "fleet.set.manage.warningmanagement",
  SETTING_EMAIL_ALERT: "fleet.set.manage.emailalert",

  // Records Management
  RCM_DOCUMENT_UPLOADS: "MANAGE_TECH_RECORD",

  // PEOPLE
  PEOPLE_ATTENDANCE: "people-atm-001",
  PEOPLE_OVERTIME: "people-atm-008",
  PEOPLE_TASK: "PPL-003",
  PEOPLE_WORK_SCHEDULE: "people-atm-003",
};
